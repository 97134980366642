<div #display class="display" dndDropzone (dndDragover)="onDragOver($event)" (dndDrop)="handleDrop($event)">

  <div class="row1">
    <div class="items">
      <div class="label track">TRACK</div>
      <div class="value track" id="track" (click)="handleTrackNumberClick($event)">{{(track$ | async)?.trackNumber |
        leadingZero }}</div>

      <div class="label time-plus">+TIME</div>
      <div class="value time-plus">{{ time$ | async | time: 'mm:ss:SS'}}</div>

      <div class="label time-remaining">-TIME</div>
      <div class="value time-remaining">-{{ remainingTime$ | async | time: 'mm:ss:SS' }}</div>


      <div class="label key">KEY</div>
      <div class="value key">{{(key$ | async) | percent:'1.1-1' }}</div>

      <div class="label pitch">PITCH</div>
      <div class="value pitch">{{(pitch$ | async) | percent:'1.1-1' }}</div>

      @if(!(isShowingTpm$ | async)) {
      <div class="label bpm" (click)="handleOpenBpmContextMenu($event)">BPM</div>
      <div class="value bpm" id="bpm">
        {{ (track$ | async)?.bpm | number: '1.1-1' }}
      </div>
      }
      @if((isShowingTpm$ | async)) {

      <div class="label bpm" (click)="handleOpenBpmContextMenu($event)">TPM</div>
      <div class="value bpm" id="tpm">
        {{ (track$ | async)?.tpm | number: '1.1-1' }}
      </div>
      }
    </div>
  </div>
  <div class="track-info">
    <ion-spinner *ngIf="(isLoading$ | async)" name="crescent"></ion-spinner>
    <ng-container *ngIf="!(isLoading$ | async)">
      <app-track-ticker [track]="track$|async" (click)="handleTrackTickerClick($event)"></app-track-ticker>
    </ng-container>
  </div>
</div>