import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BoxGeometry, Camera, Color, Mesh, MeshBasicMaterial, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { VideoTexture } from './VideoTexture';

@Component({
    selector: 'live-visual',
    templateUrl: './live-visual.component.html',
    styleUrls: ['./live-visual.component.scss'],
    standalone: false
})
export class LiveVisualComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { static: false }) canvasContainer: ElementRef;
  @ViewChild('preview1', { static: false }) preview1: ElementRef;
  @ViewChild('preview2', { static: false }) preview2: ElementRef;
  @Input('width') width: number;
  @Input('height') height: number;
  @Input('border') border: number;

  private camera: Camera;
  private scene = new Scene();
  private renderer1: WebGLRenderer;
  private renderer2: WebGLRenderer;
  private renderer3: WebGLRenderer;
  private video: HTMLElement;
  private videoTexture: VideoTexture;
  private updateFcts = [];

  private mixPreviewHeight = 0;
  private videoPreviewHeight = 0;

  private lastTimeMsec = null;
  private animationId: number;

  isPlaying = false;

  constructor(public ngZone: NgZone, private store: Store) {}

  ngOnInit() {
    this.width = this.width - this.border * 2;
    this.height = this.height - this.border * 2;

    this.mixPreviewHeight = this.height / 2 + 4;
  }

  ngDoCheck() {
    // this.mixPreviewHeight = this.canvasContainer.nativeElement.clientHeight;

    if (this.renderer1) {
      // this.renderer1.setSize(this.width, this.mixPreviewHeight);
    }
  }

  ngAfterViewInit(): void {
    /*
        this.width = this.canvasContainer.nativeElement.offsetWidth;
        this.height = this.canvasContainer.nativeElement.clientHeight;
          */

    console.log('size', this.canvasContainer.nativeElement.clientWidth);
    this.initScene();
  }

  initScene() {
    this.scene = new Scene();
    this.scene.background = new Color(0x000000);

    //camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    //camera.position.z = 5;

    this.camera = new PerspectiveCamera(50, this.width / this.mixPreviewHeight, 1, 100);
    this.camera.position.z = 1.5;

    this.renderer1 = new WebGLRenderer({ antialias: false });
    this.renderer1.setPixelRatio(window.devicePixelRatio);
    this.renderer1.setSize(this.width, this.mixPreviewHeight);

    this.renderer2 = new WebGLRenderer({ antialias: false });
    this.renderer2.setPixelRatio(window.devicePixelRatio);
    this.renderer2.setSize(this.width / 2, this.height / 2);

    this.renderer3 = new WebGLRenderer({ antialias: false });
    this.renderer3.setPixelRatio(window.devicePixelRatio);
    this.renderer3.setSize(this.width / 2, this.height / 2);

    // create the videoTexture
    this.videoTexture = new VideoTexture('assets/video/HappyBirthday.mp4');
    this.updateFcts.push(
      function (delta, now) {
        // to update the texture are every frame
        //this.videoTexture.update(delta, now)
        this.videoTexture.update();
      }.bind(this)
    );

    // use the texture in a THREE.Mesh
    var geometry = new BoxGeometry(1, 1, 1);
    var material = new MeshBasicMaterial({
      map: this.videoTexture.texture,
    });
    var videoMesh = new Mesh(geometry, material);
    this.scene.add(videoMesh);

    //$("#window1").append(renderer1.domElement);

    this.canvasContainer.nativeElement.appendChild(
      //            hostElement,
      this.renderer1.domElement
    );

    this.preview1.nativeElement.appendChild(this.renderer2.domElement);
    this.preview2.nativeElement.appendChild(this.renderer3.domElement);
  }

  playVideo() {
    var promise = this.videoTexture.video.play();
    console.log('promise');
    console.log(promise);

    if (promise !== undefined) {
      promise
        .then((_) => {
          // Autoplay started!
        })
        .catch((error) => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          alert('Play security error');
        });
    }

    //////////////////////////////////////////////////////////////////////////////////
    //		loop runner							//
    //////////////////////////////////////////////////////////////////////////////////

    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame((nowMsec) => {
        this.render(nowMsec);
      });
    });
  }

  render(nowMsec) {
    if (!this.isPlaying) return;

    // keep looping
    requestAnimationFrame(() => this.render(nowMsec));
    // measure time
    this.lastTimeMsec = this.lastTimeMsec || nowMsec - 1000 / 60;
    var deltaMsec = Math.min(200, nowMsec - this.lastTimeMsec);
    this.lastTimeMsec = nowMsec;
    // call each update function

    this.updateFcts.forEach(function (updateFn) {
      updateFn(deltaMsec / 1000, nowMsec / 1000);
    });

    this.renderer1.render(this.scene, this.camera);
    this.renderer2.render(this.scene, this.camera);
    this.renderer3.render(this.scene, this.camera);
  }

  private stopVideo() {
    if (this.videoTexture && this.videoTexture.video) {
      this.videoTexture.video.currentTime = 0;
      this.videoTexture.video.pause();
    }
  }

  private pauseVideo() {
    if (this.videoTexture && this.videoTexture.video) {
      this.videoTexture.video.pause();
    }
  }
}
