import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'note-pad',
  templateUrl: './note-pad.component.html',
  styleUrls: ['./note-pad.component.scss'],
  imports: [],
  standalone: true,
})
export class NotePadComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
