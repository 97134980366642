import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../../components/components.module';

import { DndModule } from 'ngx-drag-drop';
import { NgxSliderModule } from 'ngx-slider-v2';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { SharedUiModule } from '../../shared-ui/shared-ui.module';
import { TimePipe } from '../../shared-ui/time.pipe';
import { WaveformModule } from '../waveform/waveform.module';
import { SearchComponent } from './search/search.component';
import { TurntableComponent } from './turntable/turntable.component';
@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    IonicModule,
    SharedUiModule,
    WaveformModule,
    NgxSliderModule,
    TimePipe,
    UMButtonComponent,
    DndModule,
    //StoreModule.forFeature('player1', reducer),
    //StoreModule.forFeature('player2', reducer),
  ],
  declarations: [TurntableComponent, SearchComponent],
  exports: [],
})
export class SoundPlayerModule {}
