import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Modes } from '../../enums/modes.enum';
import { Views } from '../../enums/views.enum';
import {
  changeMode,
  changeNoPlayers,
  changeView,
  enterFullScreenModeAction,
  loginAction,
  loginSucceededAction,
  quitFullScreenModeAction,
  setWidgetAction,
  showAppleMusicAction,
  showBeatMakerAction,
  showMixerAction,
} from '../actions/general.actions';
import { showMusicArchiveAction } from './../actions/general.actions';
import { NoPlayers } from '../../enums/no-players.enum';

export interface GeneralState {
  fullscreenModeOpen: boolean;
  widget1: string;
  loggingIn: boolean;
  beatMakerVisible: boolean;
  musicArchiveVisible: boolean;
  view: Views;
  noPlayers: NoPlayers;
  mode: Modes;
  mixerVisible: boolean;
  appleMusicVisible: boolean;
}

const initialState: GeneralState = {
  fullscreenModeOpen: false,
  widget1: 'audio-sampler', //'audio-sampler',
  loggingIn: false,
  view: Views.WAITLIST,
  noPlayers: NoPlayers.TWO_PLAYERS,
  mode: Modes.ARCHIVE,
  beatMakerVisible: false,
  musicArchiveVisible: false,
  mixerVisible: false,
  appleMusicVisible: true,
};

export const generalReducer = createReducer(
  initialState,
  on(
    quitFullScreenModeAction,
    (state, action): GeneralState => ({
      ...state,
      fullscreenModeOpen: false,
    })
  ),
  on(
    enterFullScreenModeAction,
    (state, action): GeneralState => ({
      ...state,
      fullscreenModeOpen: true,
    })
  ),
  on(
    setWidgetAction,
    (state, action): GeneralState => ({
      ...state,
      widget1: action.widgetID,
    })
  ),
  on(
    loginAction,
    (state, action): GeneralState => ({
      ...state,
      loggingIn: true,
    })
  ),
  on(
    loginSucceededAction,
    (state, action): GeneralState => ({
      ...state,
      loggingIn: false,
    })
  ),
  on(
    showMixerAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: false,
      beatMakerVisible: false,
      mixerVisible: true,
      appleMusicVisible: false,
    })
  ),
  on(
    showMusicArchiveAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: true,
      beatMakerVisible: false,
      mixerVisible: false,
      appleMusicVisible: false,
    })
  ),
  on(
    showAppleMusicAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: false,
      beatMakerVisible: false,
      mixerVisible: false,
      appleMusicVisible: true,
    })
  ),
  on(
    showBeatMakerAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: false,
      beatMakerVisible: true,
      mixerVisible: false,
      appleMusicVisible: false,
    })
  ),
  on(
    changeView,
    (state, action): GeneralState => ({
      ...state,
      view: action.view,
    })
  ),
  on(
    changeMode,
    (state, action): GeneralState => ({
      ...state,
      mode: action.mode,
    })
  ),
  on(
    changeNoPlayers,
    (state, action): GeneralState => ({
      ...state,
      noPlayers: action.noPlayers,
    })
  )
);

// All getter of general state
export const generalState = createFeatureSelector<GeneralState>('general');
export const isFullscreenModeOpen = createSelector(generalState, (state: GeneralState) => state.fullscreenModeOpen);
export const getWidget1 = createSelector(generalState, (state: GeneralState) => state.widget1);
export const isLoggingIn = createSelector(generalState, (state: GeneralState) => state.loggingIn);
export const isBeatMakerVisible = createSelector(generalState, (state: GeneralState) => state.beatMakerVisible);
export const isMusicArchiveVisible = createSelector(generalState, (state: GeneralState) => state.musicArchiveVisible);
export const isMixerVisible = createSelector(generalState, (state: GeneralState) => state.mixerVisible);
export const isAppleMusicVisible = createSelector(generalState, (state: GeneralState) => state.appleMusicVisible);
export const getView = createSelector(generalState, (state: GeneralState) => state.view);
export const getMode = createSelector(generalState, (state: GeneralState) => state.mode);
export const getNoPlayers = createSelector(generalState, (state: GeneralState) => state.noPlayers);
