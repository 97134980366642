import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../../components/components.module';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { LiveVisualComponent } from '../../live-visual/live-visual.component';
import { AudioSamplerComponent } from './audio-sampler/audio-sampler.component';
import { MidiConnectionComponent } from './midi/midi-connection.component';
import { VideoMixComponent } from './video-mix/video-mix.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { NotePadComponent } from "./note-pad/note-pad.component";

@NgModule({
  imports: [CommonModule, ComponentsModule, IonicModule, UMButtonComponent, NotePadComponent],
  declarations: [WidgetsComponent, VideoMixComponent, LiveVisualComponent, AudioSamplerComponent, MidiConnectionComponent],
  providers: [],
  exports: [WidgetsComponent, VideoMixComponent, LiveVisualComponent, AudioSamplerComponent, MidiConnectionComponent],
})
export class WidgetsModule {}
