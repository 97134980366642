import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { gsap } from 'gsap';
import Smarquee from 'smarquee';
import { Track } from '../../../music-archive/track';

@Component({
  selector: 'app-track-ticker',
  templateUrl: './track-ticker.component.html',
  styleUrls: ['./track-ticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  standalone: true,
})
export class TrackTickerComponent implements OnInit, AfterViewInit, OnDestroy {
  _track: Track = null;

  @Input('track') set track(value: Track) {
    this._track = value;

    if (value) {
      this.startTicker();
    } else {
      this.stopTicker();
    }
  }
  get track(): Track {
    return this._track;
  }
  @ViewChild('ticker') ticker: ElementRef<HTMLElement>;
  @ViewChild('tickerWrapper') tickerWrapper: ElementRef<HTMLElement>;

  private totalDistance = 0;
  private startPos = 0;
  private anim: any;
  private duration = 3;
  private ro: ResizeObserver;
  private tickerWrapperWidth = 0;
  private tickerWidth = 0;

  private readonly tickerPadding = 20;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.setupResizeObserver();
    //this.setupMarquee();
  }

  setupMarquee() {
    let smarquee = new Smarquee({
      selector: null, // css selector used to grab the html element.
      element: this.ticker.nativeElement, // you can select your own element and pass it in here.
      // element supersedes the selector option.
      velocity: 50, // A represntation of the animation speed.
      // Lower is slower and higher is faster. How this affects the animation
      // will depend on the length of content being scrolled.
      styleOptions: {
        scrollingTitleMargin: 24, // in pixels. This is the size of the margin
        // between the original title and the duplicated title.
        animationName: 'marquee', // change the generated animation name.
        timingFunction: 'linear', // accepts values for the css property [animation-timing-function](https://developer.mozilla.org/en-US/docs/Web/CSS/animation-timing-function)
        iterationCount: 'infinite', // accepts numbers or 'infinite'.
        fillMode: 'none', // accepts none, forwards, backwards, or both.
        playState: 'running', // set running or paused as the initial playstate of the animation.
        delay: '0', // set delay for the start of the animation
        pausePercent: 30, // for animations that iterate more than once, you can build in a pause
        // this pause is activated at the end of the animation for X% of the remaining time in the
        // animation. This is to allow the scrolled in title to be re-read before starting the
        // animation over again. By default, this is set to 30% of the animation time.
        // From 0% to 70%, Smarquee will animate, then pause for 30% before firing the
        // onAnimationIteration() event.
      },
      // Events
      onAnimationStart() {}, // fires at the start of the animation.
      onAnimationEnd() {}, // fires when the animation is complete.
      // Does not fire if iterationCount is infinite.
      onAnimationIterate() {}, // fires when the animation iterates.
      onAnimationCancel() {}, // fires when the animation is canceled. see [MDN](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/animationcancel_event) for details. You may have issues getting this event to fire.
      onClick() {
        alert('click');
      }, // fires when the marquee is clicked.
    });

    smarquee.init();
  }

  setupResizeObserver() {
    this.ro = new ResizeObserver(this.onResize.bind(this));
    this.ro.observe(this.tickerWrapper.nativeElement);
  }

  ngOnDestroy(): void {
    if (!this.ro) {
      this.ro?.unobserve(this.tickerWrapper.nativeElement);
      this.ro?.disconnect();
    }
  }

  private onResize(entries, observer) {
    for (const entry of entries) {
      //console.log('entry', entry.target, this.tickerWrapper.nativeElement);

      // TODO: resize erst nach einem bestimmten Delay, damit es nicht zu häufig passiert beim ändern der Browsergröße

      if (entry.target === this.tickerWrapper.nativeElement) {
        this.tickerWrapperWidth = entry.contentRect.width;
        this.tickerWidth = this.ticker.nativeElement.getBoundingClientRect().width;
        if (this.tickerWidth > 0) {
          this.startTicker();
        } else {
          this.stopTicker();
        }
      }
    }
  }

  startTicker() {
    if (!this.tickerWrapper) return;

    if (this.anim && this.track) {
      this.anim.play(0);
    }
    this.totalDistance = this.tickerWrapperWidth - this.tickerWidth + this.tickerPadding;
    //console.log('>>> totalDistance', this.totalDistance);

    if (this.tickerWrapper.nativeElement && this.totalDistance > 0) {
      this.anim = gsap.to(this.tickerWrapper.nativeElement, {
        duration: this.duration,
        x: -this.totalDistance,
        ease: 'none',
        yoyo: true,
        repeat: -1,
        overwrite: true,
      });
    }
  }

  stopTicker(): void {
    if (this.anim) {
      this.anim.pause(0);
    }
  }
}
