import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, take, tap } from 'rxjs';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { PitchFaderComponent } from './pitch-fader/pitch-fader.component';

import { AsyncPipe, CommonModule } from '@angular/common';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
  addOutline,
  caretBackOutline,
  caretDownOutline,
  caretForwardOutline,
  caretUpOutline,
  removeOutline,
  settingsOutline,
} from 'ionicons/icons';
import { PitchMode } from 'src/app/models/pitch-mode';
import {
  activatePlayerAction,
  cueAction,
  decreaseLoopBeatAction,
  increaseLoopBeatAction,
  loopBeatAction,
  loopInAction,
  loopOutAction,
  playAction,
  setCuePointAction,
  setPitchAction,
  togglePitchModeAction,
  togglePlayedToSyncToAction,
} from '../../core/redux/actions/player.actions';
import {
  PlayerState,
  getLoopBeatRange,
  getPitchMode,
  getPlayerIDToSyncTo,
  getTrack,
  getWaveformCache,
  isLoading,
  isPlayButtonDisabled,
  isPlayerActive,
  isPlaying,
} from '../../core/redux/reducers/player.reducer';
import { Track } from '../../music-archive/track';
import { AudioService } from '../../services/audio.service';
import { WaveformModule } from '../waveform/waveform.module';
import { WaveformCache } from '../waveform/WaveformCache';
import { DisplayComponent } from './display/display.component';
import { TurntableComponent } from './turntable/turntable.component';
import { Turntable2Component } from './turntable2/turntable2.component';

@Component({
  selector: 'sound-player',
  templateUrl: './sound-player.component.html',
  styleUrls: ['./sound-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonIcon,
    UMButtonComponent,
    AsyncPipe,
    PitchFaderComponent,
    WaveformModule,
    Turntable2Component,
    DisplayComponent,
  ],
})
export class SoundPlayerComponent implements OnInit {
  isPlaying$: Observable<boolean>;
  playButtonDisabled$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  isPlayerActive$: Observable<boolean>;
  track$: Observable<Track>;
  loopRange$: Observable<Number>;
  currentScratchingPosition$: Observable<number>;

  pitchMode$: Observable<PitchMode>;
  playerIDToSyncTo$: Observable<number>;

  @ViewChild('cueButton', { static: false }) cueButton: UMButtonComponent;
  @ViewChild('playButton', { static: false }) playButton: UMButtonComponent;
  @ViewChild('turntable', { static: false }) turntable: TurntableComponent;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('pitchSlider', { static: false }) pitchSlider: PitchFaderComponent;

  @Input() playerID: number;

  waveformCache$: Observable<WaveformCache>;

  constructor(private store: Store<PlayerState>, private audioService: AudioService) {
    addIcons({ settingsOutline, caretUpOutline, caretDownOutline, addOutline, removeOutline, caretForwardOutline, caretBackOutline });
  }

  ngOnInit() {
    this.isPlaying$ = this.store.select(isPlaying(this.playerID));
    this.isLoading$ = this.store.select(isLoading(this.playerID));
    this.playButtonDisabled$ = this.store.select(isPlayButtonDisabled(this.playerID));

    this.waveformCache$ = this.store.select(getWaveformCache(this.playerID));
    this.pitchMode$ = this.store.select(getPitchMode(this.playerID));
    this.playerIDToSyncTo$ = this.store.select(getPlayerIDToSyncTo(this.playerID));
    this.isPlayerActive$ = this.store.select(isPlayerActive(this.playerID));
    this.track$ = this.store.select(getTrack(this.playerID));
    this.loopRange$ = this.store.select(getLoopBeatRange(this.playerID));
  }

  handlePlay() {
    this.store.dispatch(playAction({ playerID: this.playerID }));
  }

  handleCue() {
    this.store.dispatch(cueAction({ playerID: this.playerID }));
  }

  getFileUrlFromFile(file: any) {
    return URL.createObjectURL(file);
  }

  handleLoopIn() {
    let pos = this.audioService.getPosition(this.playerID);
    this.store.dispatch(loopInAction({ playerID: this.playerID, time: pos }));
  }

  handleLoopOut() {
    let pos = this.audioService.getPosition(this.playerID);
    this.store.dispatch(loopOutAction({ playerID: this.playerID, time: pos }));
  }

  handleSetCuePoint(cuepointIndex: number) {
    this.store.dispatch(setCuePointAction({ playerID: this.playerID, cuepointIndex }));
  }

  handleLoopBeat() {
    this.store.dispatch(loopBeatAction({ playerID: this.playerID }));
  }

  handleIncreaseLoopBeat() {
    this.store.dispatch(increaseLoopBeatAction({ playerID: this.playerID }));
  }
  handleDecreaseLoopBeat() {
    this.store.dispatch(decreaseLoopBeatAction({ playerID: this.playerID }));
  }

  handlePitchChanged(value: number) {
    this.store.dispatch(setPitchAction({ playerID: this.playerID, value }));
  }

  handleResetPitch() {
    this.pitchSlider.reset();
  }

  handleIncreasePitch() {
    this.pitchSlider.setValue(this.pitchSlider.getValue() + 0.1);
  }

  handleDecreasePitch() {
    this.pitchSlider.setValue(this.pitchSlider.getValue() - 0.1);
  }

  handleTogglePitchMode() {
    this.store.dispatch(togglePitchModeAction({ playerID: this.playerID })); 
  }

  handleSync() {
    throw new Error('Method not implemented.');
  }

  handleTogglePlayer() {
    this.store.dispatch(togglePlayedToSyncToAction({ playerID: this.playerID }));
  }

  handleToggleActivePlayer() {
    this.isPlayerActive$
      .pipe(
        take(1),
        filter((isActive) => !isActive),
        tap(() => {
          this.store.dispatch(activatePlayerAction({ playerID: this.playerID }));
        })
      )
      .subscribe();
  }

  handlePitchConfig() {}
}
