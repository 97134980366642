<div class="player" [ngClass]="{ playing: isPlaying$ | async }" [attr.playerID]="playerID">

  <div class="player-panels">

    <!-- display 0 0 -->
    <div class="display-panel">
      <um-button class="player-switch-button" [selected]="isPlayerActive$ | async"
        (click)="handleToggleActivePlayer()">{{playerID}}</um-button>
      <app-display [playerID]="playerID" *ngIf="true"></app-display>
      <div class="turntable-section">
        <turntable2 *ngIf="true" class="turntable" [playing]="isPlaying$ | async" [loading]="isLoading$ | async"
          [coverImage]="(track$ | async)?.coverUrl" [scratching]="currentScratchingPosition$ | async">
        </turntable2>
      </div>
    </div>
    <!-- Play, cue, loop 0 1, ... -->
    <div class="blocks">
      <div class="block cues">
        <div class="label">
          <div class="inner">CUES</div>
        </div>
        <div class="button-grid cue-buttons">
          <button class="mini-button" (click)="handleSetCuePoint(0)">1</button>
          <button class="mini-button" (click)="handleSetCuePoint(1)">2</button>
          <button class="mini-button" (click)="handleSetCuePoint(2)">3</button>
          <button class="mini-button" (click)="handleSetCuePoint(3)">4</button>
          <button class="mini-button" (click)="handleSetCuePoint(4)">5</button>
          <button class="mini-button" (click)="handleSetCuePoint(5)">6</button>
          <button class="mini-button" (click)="handleSetCuePoint(6)">7</button>
          <button class="mini-button" (click)="handleSetCuePoint(7)">8</button>
        </div>
      </div>

      <div class="block loops">
        <div class="label">
          <div class="inner">LOOPS</div>
        </div>
        <div class="loop-buttons">
          <div class="loop-range">
            <um-button class="decrease-loop-range" (click)="handleDecreaseLoopBeat()">-</um-button>
            <um-button class="set-loop-range" [toggleButton]="true" (click)="handleLoopBeat()"> {{ loopRange$ | async
              }}</um-button>
            <um-button class="increase-loop-range" (click)="handleIncreaseLoopBeat()">+</um-button>
          </div>
          <div class="in-out-panel">
            <um-button class="mini-button" toggle="true" (click)="handleLoopIn()">IN</um-button>
            <um-button class="mini-button" toggle="true" (click)="handleLoopOut()">OUT</um-button>
          </div>
        </div>
      </div>

      <div class="play-cue-panel">
        <um-button #cueButton class="cue-button" [toggleButton]="false" (click)="handleCue()"
          [selected]="!(isPlaying$ | async)"> CUE </um-button>
        <um-button #playButton class="play-button" [toggleButton]="false" (click)="handlePlay()"
          [selected]="isPlaying$ | async" [disabled]="playButtonDisabled$|async">PLAY </um-button>
        <um-button #playButton class="play-cue-button" [disabled]="playButtonDisabled$|async" [toggleButton]="false"
          (click)="handlePlay()" [selected]="isPlaying$ | async">
          <svg class="icon" width="681" height="363" viewBox="0 0 681 363" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="26.5" width="310" height="310" />
            <path d="M681 181.5L414.75 338.684V24.3164L681 181.5Z" />
          </svg>
        </um-button>
      </div>
    </div>
    <!-- pitch 1 1-->
    <section class="pitch-section">

      <button class="mini-button pitch-time-toggle" (click)="handleTogglePitchMode()">{{pitchMode$ | async}}
      </button>
      <button class="mini-button pitch-time-config" (click)="handlePitchConfig()">
        <ion-icon name="settings-outline"></ion-icon>
      </button>

      <app-pitch-fader #pitchSlider (pitch)="handlePitchChanged($event)"></app-pitch-fader>

      <section class="pitch-buttons">
        <section class="pitch-value-buttons">
          <button class="mini-button" (click)="handleIncreasePitch()">
            <ion-icon name="caret-up-outline"></ion-icon>
          </button>
          <button class="mini-button" (click)="handleDecreasePitch()">
            <ion-icon name="caret-down-outline"></ion-icon>
          </button>
        </section>
        <button class="mini-button" (click)="handleResetPitch()">0</button>
        <section class="pitch-bend-buttons">
          <button class="mini-button" (click)="handleResetPitch()">
            <ion-icon name="add-outline"></ion-icon>
          </button>
          <button class="mini-button" (click)="handleResetPitch()">
            <ion-icon name="remove-outline"></ion-icon>
          </button>
        </section>
      </section>

      <button class="mini-button sync-button" (click)="handleSync()">
        <ng-container *ngIf="playerID==0">
          sync <ion-icon name="caret-forward-outline"></ion-icon>
        </ng-container>
        <ng-container *ngIf="playerID==1">
          <ion-icon name="caret-back-outline"></ion-icon>sync
        </ng-container>
      </button>
      <button class="mini-button sync-player-button" (click)="handleTogglePlayer()">
        {{ playerIDToSyncTo$ |async}}
      </button>

    </section>

    <!--<search class="mini-waveform" [playerID]="playerID"></search>//-->
    <bs-waveform class="mini-waveform" [loading]="isLoading$ | async"
      [cacheContent]="waveformCache$ | async"></bs-waveform>
  </div>

</div>