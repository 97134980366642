import { createAction, props } from '@ngrx/store';
import { Modes } from '../../enums/modes.enum';
import { Views } from '../../enums/views.enum';
import { NoPlayers } from '../../enums/no-players.enum';

const ACTION_TYPE = '[General]';

export const toggleFullScreenModeAction = createAction(`${ACTION_TYPE} Toggle fullscreen mode`);
export const enterFullScreenModeAction = createAction(`${ACTION_TYPE} Enter fullscreen mode`);
export const quitFullScreenModeAction = createAction(`${ACTION_TYPE} Quit fullscreen mode`);
export const setWidgetAction = createAction(`${ACTION_TYPE} Set widget`, props<{ slotID: number; widgetID: string }>());

export const showMusicArchiveAction = createAction(`${ACTION_TYPE} Show music archive`);
export const showAppleMusicAction = createAction(`${ACTION_TYPE} Show apple music`);
export const showBeatMakerAction = createAction(`${ACTION_TYPE} Show beatmaker`);
export const showMixerAction = createAction(`${ACTION_TYPE} Show mixer`);

export const changeView = createAction(`${ACTION_TYPE} Change view`, props<{ view: Views }>());
export const changeMode = createAction(`${ACTION_TYPE} Change mode`, props<{ mode: Modes }>());
export const changeNoPlayers = createAction(`${ACTION_TYPE} Change no players`, props<{ noPlayers: NoPlayers }>());

export const loginAction = createAction(`${ACTION_TYPE} Login started`, props<{ username: string; password: string }>());
export const loginSucceededAction = createAction(`${ACTION_TYPE} Login succeeded`);
