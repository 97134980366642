<div class="root">
    <ion-segment (ionChange)="widgetChanged($event)" value="audio-sampler">
        <ion-segment-button value="video-mix">
            <ion-label>Video</ion-label>
        </ion-segment-button>
        <ion-segment-button value="audio-sampler">
            <ion-label>Sampler</ion-label>
        </ion-segment-button>
        <ion-segment-button value="note-pad">
            <ion-label>Notizen</ion-label>
        </ion-segment-button>
        <!--
        <ion-segment-button value="midi-connection">
            <ion-label>Midi</ion-label>
        </ion-segment-button>
        
        <ion-segment-button value="live-visuals">
            <ion-label>Visuals</ion-label>
        </ion-segment-button>
        //-->
    </ion-segment>

    <ng-container [ngSwitch]="(widget1$ | async)">
        <audio-sampler *ngSwitchCase="'audio-sampler'" [noSlots]="16"></audio-sampler>
        <live-visual *ngSwitchCase="'live-visuals'" [width]="200" [height]="300" [border]="2"></live-visual>
        <video-mix *ngSwitchCase="'video-mix'"></video-mix>
        <midi-Connection *ngSwitchCase="'midi-connection'"></midi-Connection>
        <note-pad *ngSwitchCase="'note-pad'"></note-pad>
    </ng-container>
</div>

<!--
       <div class="widgets">
           <as-split [direction]="'vertical'" [unit]="'percent'" [gutterSize]="8">
               <as-split-area [size]="50">

</as-split-area>
<as-split-area [size]="50">

</as-split-area>
</as-split>
</div>
//-->