import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { VisualsService } from '../../../services/visuals.service';

@Component({
    selector: 'video-mix',
    templateUrl: './video-mix.component.html',
    styleUrls: ['./video-mix.component.scss'],
    standalone: false
})
export class VideoMixComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { static: false }) canvasContainer: ElementRef;
  @Input('width') width: number;
  @Input('height') height: number;
  @Input('border') border: number;

  private mixPreviewHeight = 0;

  isPlaying = false;

  constructor(public ngZone: NgZone, private store: Store, private visualsService: VisualsService) {}

  // private el: ElementRef, 
  ngOnInit() {
    this.width = this.width - this.border * 2;
    this.height = this.height - this.border * 2;

    this.mixPreviewHeight = this.height / 2 + 4;
  }

  ngAfterViewInit(): void {
    /*
        this.width = this.canvasContainer.nativeElement.offsetWidth;
        this.height = this.canvasContainer.nativeElement.clientHeight;
          */
    this.visualsService.addOutput(this.canvasContainer);
  }
}
