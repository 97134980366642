import { createAction, props } from '@ngrx/store';
import { BeatMakerPattern } from '../../../main/beatmaker/beat-maker-pattern';
import { BeatMakerTrack } from '../../../main/beatmaker/beat-maker-track';

const ACTION_TYPE = '[BeatMaker]';

export const loadPatternAction = createAction(`${ACTION_TYPE} Loading Pattern`, props<{ pattern: BeatMakerPattern }>());
export const loadPatternSucceededAction = createAction(`${ACTION_TYPE} Loading Pattern succeeded`, props<{ pattern: BeatMakerPattern }>());
export const loadPatternFailedAction = createAction(
  `${ACTION_TYPE} Loading Pattern failed`,
  props<{ pattern: BeatMakerPattern; error: any }>()
);

export const soloTrackAction = createAction(
  `${ACTION_TYPE} Soloing Track`,
  props<{ track: BeatMakerTrack; trackIndex: number; solo: boolean }>()
);

export const muteTrackAction = createAction(
  `${ACTION_TYPE} Muting Track`, 
  props<{ trackIndex: number; track: BeatMakerTrack; mute: boolean }>()
);

export const playBeatAction = createAction(`${ACTION_TYPE} Playing Beat`);
export const stopBeatAction = createAction(`${ACTION_TYPE} Stoping Beat`);

export const toggleTrackBarAction = createAction(
  `${ACTION_TYPE} Toggling Track bar`,
  props<{ pattern?: BeatMakerPattern; track: BeatMakerTrack; barIndex: number }>()
);

export const setCurrentPlayingBarAction = createAction(`${ACTION_TYPE} Setting current playing bar`, props<{ barIndex: number }>());

export const setSpeedAction = createAction(`${ACTION_TYPE} Setting BeatMaker speed`, props<{ bpm: number }>());

export const seekAction = createAction(`${ACTION_TYPE} Seeking`, props<{ barIndex: number }>());

export const enableEchoAction = createAction(`${ACTION_TYPE} Enabling echo`);
export const disableEchoAction = createAction(`${ACTION_TYPE} Disabling echo`);
export const toggleEchoAction = createAction(`${ACTION_TYPE} Toggling echo`);

export const initializingBeatMakerAction = createAction(`${ACTION_TYPE} Initializing BeatMaker`);
export const initializingBeatMakerSucceededAction = createAction(
  `${ACTION_TYPE} Initializing BeatMaker succeeded`,
  props<{ pattern: BeatMakerPattern }>()
);
export const initializingBeatMakerFailedAction = createAction(`${ACTION_TYPE} Initializing BeatMaker failed`, props<{ error: any }>());
